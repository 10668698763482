<template>
	<section class="permission">
		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="username" label="用户名" width="150">
			</el-table-column>
			<el-table-column prop="name" label="店名" width="150">
            </el-table-column>            
			<el-table-column prop="my_host" label="域名">
			</el-table-column>
			<el-table-column prop="store_status" label="域名状态" width="100">
      		</el-table-column>            
			<el-table-column prop="contact" label="姓名" width="150">
			</el-table-column>
			<el-table-column prop="mobile" label="手机号" width="150">
			</el-table-column>			
			<el-table-column label="操作" width="150" align="center">
				<template slot-scope="scope">
					<template>
						<el-button type="primary" plain size="small" @click="handleReg(scope.row)" title="确认注册">注 册</el-button>
					</template>
				</template>
			</el-table-column>
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>
		<el-dialog title="注册确认" :visible.sync="dialogShow" width="500px" center>
			<el-form :model="storeInfo" label-width="70px">
				<el-form-item label="店名">
					<el-input v-model="storeInfo.name" placeholder="输入店名" @input="check" class="w300"></el-input>
					<!-- <i v-if="repeat" class="el-icon-circle-check green fz24"></i>
					<i v-else class="el-icon-circle-close red fz24"></i> -->
				</el-form-item>
				<el-form-item label="域名">
					<el-input v-model="storeInfo.my_host" placeholder="输入域名" class="w300"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submit">注册确认</el-button>
				<el-button @click="dialogShow = false">取 消</el-button>
			</span>
		</el-dialog>
	</section>
</template>

<script>
	import { getThoststatus, hostRegister } from '../../api/api';
	export default {
		data() {
			return {
				filters: {
					keyword: ''
				},
				storeInfo:{
					store_id: '',
					name: '',
					my_host: ''
				},
				allStoreName: [],
				repeat: true,
				dialogShow: false,
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				sels: [],//列表选中列
			}
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取用户列表
			getList(val) {
                if(val == '0') this.page = 1
				let para = {
					//page: this.page,
					host_status: 0, //0-未注册，1-已注册，2-等待解析，3-开通成功
					//pagesize: this.pagesize	//分页条数
				};
				this.listLoading = true;
				getThoststatus(para).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
            },
			
			handleReg(data){
				this.dialogShow = true;
				this.storeInfo.name = data.name;
				this.storeInfo.store_id = data.store_id;
				this.storeInfo.my_host = data.my_host;
				// 校验是否重名
				// this.check()
			},
			// 店名变化校验是否重名
			check(){
				var _t = this;
				this.allStoreName.some(item=>{
					if(item.store_name == this.storeInfo.store_name){
						_t.repeat = false; 
						return true
					}else{
						_t.repeat = true; 
					}
				})
			},
			submit(){
				// if(!this.repeat) return this.$message.error('店名重复了！');
				if(!this.storeInfo.name) return this.$message.error('店名不能为空！');
				if(!this.storeInfo.my_host) return this.$message.error('域名不能为空！');
				// 注册接口
				hostRegister(this.storeInfo).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.data;
						this.dialogShow = false;
						this.getList();
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			}
		},
		mounted() {
			this.getList();
		}
	}

</script>

<style lang="scss">
	@import "../../styles/permission.scss";
</style>